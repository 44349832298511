import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { takeUntil } from 'rxjs';
import { EventBroadcastService } from 'src/app/services/event-broadcast.service';
import { BaseComponent } from 'src/app/utility/base.component';
import {
  BroadcastEvents,
  FabActions,
  FabMessage,
} from 'src/app/utility/model/broadcast-payload';

@Component({
  selector: 'app-float-add-btn',
  templateUrl: './float-add-btn.component.html',
  styleUrls: ['./float-add-btn.component.scss'],
})
export class FloatAddBtnComponent extends BaseComponent implements OnChanges {
  @Input() drawerContent: MatDrawerContent;
  fabStore: FabMessage = {
    heart: false,
    plus: false,
    scroll: false,
  };
  actionTypes = FabActions;

  constructor(private eventService: EventBroadcastService) {
    super();
    this.eventService.getEvent<FabMessage>(BroadcastEvents.update_fab_store)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fabRes: FabMessage) => {
        this.resetFabStore();
        this.fabStore = {
          ...this.fabStore,
          ...fabRes,
        };
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['drawerContent'] && changes['drawerContent'].currentValue) {
      this.drawerContent.getElementRef().nativeElement.onscroll = () => {
        this.onScroll();
      };
    }
  }

  onAction(actionType: FabActions) {
    if (actionType === FabActions.scroll) {
      this.scrollToTop();
    } else {
      this.eventService.broadcast(BroadcastEvents.fab_action, actionType);
    }
  }

  resetFabStore(): void {
    this.fabStore = {
      heart: false,
      plus: false,
      scroll: false,
    };
  }

  onScroll() {
    const scrollElement = this.drawerContent.getElementRef().nativeElement as HTMLElement;
    this.fabStore.scroll = scrollElement.scrollTop > (2 * scrollElement.clientHeight);
  }

  scrollToTop() {
    this.drawerContent.getElementRef().nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
