export default {
  address: {
    contactNumberLength: 0,
    contactNumberMinLength: 0,
    emailRequired: true,
    hidePin: true,
    hideCountry: true,
    fixCountry: '',
    pinCodeRequired: false,
    pinCodeLength: 0,
    pinCodeDigitOnly: false,
  },
  app: 'pru-pss',
  appName: 'PRU-PSS',
  applyBgColorFromDjango: false,
  allowInsufficientPoint: true,
  registerNewAccount: false,
  allowPayment: false,
  contactInfo: {},
  defaultCurrency: 'SGD',
  defaultLanguage: 'en',
  defaultUserIcon: 'assets/img/user.png',
  enableNomination: false,
  enforceDefaultLanguage: true,
  customRewardCategory: true,
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  freshchat: {
    isShow: true,
    pages: 'all',
  },
  footer: {
    dynamicLogo: true,
    getContactDetailsFromOrg: true,
  },
  header: {
    dynamicBg: false,
    dynamicLogo: true,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: false,
  },
  headerLogoMaxHeight: '30px',
  home: {
    featuredRewards: false,
    topRewards: true,
    recommendation: false,
  },
  languageAvailable: ['en'],
  languageLabel: {
    en: 'English',
  },
  links: {},
  loginReadMore: false,
  loginTermsCondition: false,
  goToAdminDashboard: false,
  panel: {
    link: ['/profile/edit'],
    support: false,
  },
  pointDetailHeader: 'common.pointsRecord',
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: false,
      rating: false,
    },
  },
  settings: {
    hideMyAppreciationMessagesToggle: false,
    makeMyBirthdayPublicToggle: false,
    makeMyAnniversaryPublicToggle: false,
    makeMyProfilePublicToggle: false,
  },
  voucherStatus: [0, 2, 3, 5, 8, 9, 11],
  zhLang: 'zh-hant',
  isUserRewardPreferenceEnabled: false,
  modules: {
    feeds: false,
  },
};
