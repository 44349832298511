export const BroadcastEvents = {
    'update_fab_store': 'update_fab_store',
    'fab_action': 'fab_action'
}

export enum FabActions {
    'heart',
    'plus',
    'scroll'
}

export interface FabMessage {
    heart?: boolean;
    plus?: boolean;
    scroll?: boolean;
}