import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface EventPayload<T = any> {
  event: string;
  data?: T;
}

@Injectable({
  providedIn: 'root',
})
export class EventBroadcastService {
  private eventSubject = new Subject<EventPayload>();

  // Emit an event with strongly typed payload
  broadcast<T>(event: string, data?: T) {
    this.eventSubject.next({ event, data });
  }

  // Listen for events with specific type
  getEvent<T>(event: string): Observable<T> {
    return new Observable<T>(observer => {
      const subscription = this.eventSubject.subscribe(({ event: e, data }) => {
        if (e === event) {
          observer.next(data as T); // Type assertion for strong typing
        }
      });
      return () => subscription.unsubscribe();
    });
  }
}
